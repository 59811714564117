// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  top: 0;
  margin: 0;
}

.loader .icon {
  font-size: 3em;
  color: #50b570;
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/loader.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;AADF;;AAIA;EACE,cAAA;EACA,cCdgB;ADalB","sourcesContent":["@import '../../styles/colors.scss';\n\n.loader {\n  background-color: #fff;\n  width: 100%;\n  height: 100vh;\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-self: center;\n  top: 0;\n  margin: 0;\n}\n\n.loader .icon {\n  font-size: 3em;\n  color: $secondary-color;\n}\n","$text-color: #464a4e;\n$title-color: #4a4f5d;\n$primary-color: #00a84a;\n$secondary-color: #50b570;\n$tertiary-color: #10a574;\n$quaternary-color: #00a84a;\n$hover-primary: #008241;\n$status-valid: #3cba54;\n$status-invalid: #db3236;\n$status-pending: #f4c20d;\n$status-doubt: #722ed1;\n$active-button: #4295ff;\n$inactive-button: #b7b7b7;\n$background-color: #f1f3f4;\n$black: #000000;\n$white: #ffffff;\n$printerTable-color: #00f26b1a;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
