// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-notification-notice:not(:last-child) .ant-notification-notice-btn {
  display: none;
}
.ant-notification-notice .ant-notification-notice-btn {
  position: fixed;
  left: calc(50% - 58.5px);
}
.ant-notification-notice .ant-notification-notice-btn button {
  margin-top: 10px !important;
}

.ant-notification-top {
  z-index: 10000;
}`, "",{"version":3,"sources":["webpack://./src/styles/notification.scss"],"names":[],"mappings":"AAGQ;EACI,aAAA;AAFZ;AAKI;EACE,eAAA;EACA,wBAAA;AAHN;AAIQ;EACE,2BAAA;AAFV;;AAOA;EACE,cAAA;AAJF","sourcesContent":[".ant-notification-notice {\n\n    &:not(:last-child){\n        .ant-notification-notice-btn{\n            display: none;\n        }\n    }\n    .ant-notification-notice-btn {\n      position: fixed;\n      left: calc(50% - 58.5px);\n        button {\n          margin-top: 10px!important;\n        }\n       }\n}\n\n.ant-notification-top {\n  z-index: 10000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
